import {
  Properties,
  ReportInvoicesStatuses,
  InvoiceStatus,
  NormalizeType,
  Address,
  SelectOption,
  FactoringType,
  HistoryRatesData,
} from 'types';
import { hasOwnProperty } from './objects';

type DataProp = Properties[];

interface GetIndexesProps {
  key: string;
  data: DataProp;
}

export const getIndexes = ({ data, key }: GetIndexesProps): Properties => {
  const indexes = {};

  data.forEach((item) => {
    if (!indexes[item[key]]) {
      indexes[item[key]] = data.filter((i) => i[key] === item[key]).length;
    }
  });

  return indexes;
};

interface FormatByIndexesProps {
  key: string;
  data: DataProp;
  indexes: Properties;
}

export const formatByIndexes = ({ data, indexes, key }: FormatByIndexesProps): DataProp => {
  let index = 0;
  const newData: DataProp = [];

  data.forEach(({ [key]: i, ...item }) => {
    if (indexes[i] && !index) {
      newData.push({ [key]: i, ...item });
      index = indexes[i] - 1;
    } else {
      newData.push(item);
      index -= 1;
    }
  });

  return newData;
};

/**
 * Sum the reports by statuses
 * @param {Array} data An array containing Reports
 * @param {Array} statuses An array containing Invoice statuses
 * @example <caption>Sum the reports of the approved status</caption>
 * reduceTotal(data, [InvoiceStatus.APPROVED]);
 *
 * @returns {number} The sum of the reports by statuses
 */
export const reduceTotal = (
  data: ReportInvoicesStatuses[] = [],
  statuses: InvoiceStatus[],
): number =>
  data
    .filter(({ status }) => statuses.includes(status))
    .reduce((acc: number, cur: { total: number }): number => acc + cur.total, 0);

export const getPaths = (paths) => (Array.isArray(paths) ? paths : paths.split('.'));

/**
 * Set value to nested object
 * @param {object} item the object of data.
 * @param {any} value the value to set.
 * @param {array} paths the keys of nested objects.
 * @example <caption>Normalize an object, containing numbers or percentage</caption>
 * setDataValue({ a: { b: { c: { value: '' } } } }, 'example', 'a.b.c.value');
 */
export const setValue = (item, value, paths) => {
  let obj = { ...item },
    val;
  paths = getPaths(paths);

  while (paths.length > 1) {
    val = paths.shift();

    if (obj[val]) {
      obj = obj[val];
    }
  }

  val = paths.shift();

  if (obj[val]) {
    obj[val] = value;
    item[paths[0]] = obj;
  }

  return item;
};

/**
 * Get value from nested object
 * @param {object} item the object of data.
 * @param {array} paths the keys of nested objects.
 * @example
 * getValue({ a: { b: { c: { value: '' } } } }, 'a.b.c.value');
 */
export const getValue = (item, paths) => {
  let obj = { ...item };
  paths = getPaths(paths);

  for (let i = 0; i < paths.length; ++i) {
    if (obj && paths[i] in obj && obj[paths[i]]) {
      obj = obj[paths[i]];
    }
  }

  return obj;
};

/**
 * Normalize children of object data.
 * @param {object} item the object of data.
 * @param {object|array} params the settings to format data.
 * @example <caption>Normalize an object, containing numbers or percentage</caption>
 * normalizeData(item, [{ properties: ['percent', ...], wrapper: (value) => formatNumber(value, '%') }]);
 */
export const normalizeData = (item, params: NormalizeType | NormalizeType[]) => {
  const normalize = (prop, param?) => {
    const wrapper = (value) => (param || params).wrapper(value);
    const paths = prop.split('.');

    if (paths.length > 1) {
      const value = wrapper(getValue(item, paths));

      item = setValue(item, value, paths);
    } else if (paths.length) {
      const path = paths[0];

      item[path] = wrapper(item[path]);
    }
  };

  if (Array.isArray(params)) {
    params.forEach((param) => {
      param.properties.forEach((prop) => normalize(prop, param));
    });
  } else if (params.properties) {
    params.properties.forEach((prop) => normalize(prop));
  }

  return item;
};

/**
 * Transform string from request response string in an object or array, while you have the ability to normalize their data
 * @param {string} response the string of request response.
 * @param {object|array} normalize the settings for data normalization.
 * @example <caption>Using to normalize children of object data</caption>
 * transformResponse(response, [{ properties: ['payed', ...], wrapper: (value) => formatNumber(value) }]);
 */
export const transformResponse = (response, normalize?: NormalizeType | NormalizeType[]) => {
  const data = typeof response !== 'object' ? JSON.parse(response) : response;

  if (normalize) {
    const getNormalizedData = (item) => normalizeData(item, normalize);

    return hasOwnProperty(data, 'results')
      ? {
          ...data,
          results: data.results.map(getNormalizedData),
        }
      : Array.isArray(data)
      ? data.map(getNormalizedData)
      : getNormalizedData(data);
  }

  return data;
};

/**
 * Determine if an array contains one or more items from another array.
 * @param {array} source the array to search.
 * @param {array} target the array providing items to check for in the source.
 * @example <caption>Check if user roles contains multiple roles</caption>
 * arrayContainsArray(roles, userRoles);
 *
 * @return {boolean} true|false if source contains at least one item from target.
 */
export const arrayContainsArray = (source, target) => {
  return target.some((v) => source.includes(v));
};

export const extractKeys = (data, callback: (data, description) => void, title?) => {
  Object.keys(data).forEach((key) =>
    typeof data[key] === 'object'
      ? extractKeys(data[key], callback, key)
      : callback(title || key, data[key]),
  );
};

/**
 * Extracting keys & values from data response then will pass every params in callback func.
 * @param {object} data the response data.
 * @param {array} callback the function to pass key & value
 * @example <caption>Extract errors & show it in notifications</caption>
 * extractResponseProps(err, (title, description) => notify.error({ title: t(title), description: t(description) })
 */
export const extractResponseProps = (data, callback: (title, description) => void) => {
  if (data?.response?.data && data?.response?.status >= 400 && data?.response?.status < 500) {
    extractKeys(data.response.data, callback);
  }

  if (data?.response?.status >= 500) {
    callback(data?.response?.statusText, data?.message);
  }
};

/**
 * Compose the full address
 * @param address Address object
 * @returns full address
 */
export const getFullAddress = (address?: Address): string => {
  if (!address) {
    return '---';
  }

  return address.title || '---';
};

/**
 * Get options from results
 * @param results Results data
 * @returns select options
 */
export const transformResults = (
  response: string | Properties[],
  valueLabel?: string,
  textLabel?: string,
): SelectOption[] => {
  const isJson = typeof response === 'string';
  const data = isJson ? JSON.parse(response as string) : response;
  const results = isJson ? data.results || [] : response;

  return results.map((result) => ({
    value: valueLabel ? result?.[valueLabel as string] : result?.id,
    text: textLabel
      ? result?.[textLabel as string]
      : result?.title || result?.name || result?.code_name || '---',
  }));
};

/**
 * Split string into numbers and letters
 * @param value
 */
const splitIntoParts = (value: string) => value && value.match(/[a-zA-Z]+|[0-9]+/g);

/**
 * Replace indicators of classifier in formula with indicator row
 * @param formula
 * @param classifier
 */
export const renderFormula = (formula: string, classifier): React.ReactNode[] => {
  if (formula && classifier?.indicators) {
    Object.keys(classifier.indicators).forEach((indicator) => {
      if (classifier.indicators && indicator in classifier.indicators) {
        formula = formula.replaceAll(indicator, `rd.${classifier.indicators[indicator].row}`);
      }
    });
  }

  return formula.split(/rd.([0-9]+)/).filter((item) => item);
};

/**
 * Merge company classifiers with list of classifiers
 * @param data
 * @param classifiers
 */
export const mergeClassifiersData = (data, classifiers) => {
  if (!data?.classifiers || !classifiers) {
    return [];
  }

  const mergedData = data?.classifiers
    .map((classifier, idx) => {
      const foundClassifier = classifiers.find((item) => item.id === classifier.id);

      if (foundClassifier) {
        return {
          ...classifier,
          step: idx + 1,
          classifier: foundClassifier,
          renderFormula: renderFormula(foundClassifier.formula, foundClassifier),
          category: data.category,
        };
      }
    })
    .filter((v) => v);

  if (mergedData.length) {
    const totals = mergedData.reduce((prev, curr) => {
      const [total, type] = splitIntoParts(curr?.score);

      return {
        ...prev,
        [type]: prev[type] ? prev[type] + Number(total) : Number(total),
      };
    }, {});

    return [
      ...mergedData,
      {
        step: true,
        score: Object.entries(totals)
          .map((entry) => entry.reverse().join(''))
          .join(', '),
      },
    ];
  }

  return mergedData;
};

export const getCategoryRatesByType = (categoryRates, type = FactoringType.REGRESSION) =>
  categoryRates?.find((categoryRate) => categoryRate?.factoring_type?.type === type);

export const formatCategoryRates = (categoryRate) => {
  const categoryRatesData: Properties[] = [];

  const allRatesLength = categoryRate?.categories
    ?.map((categoty) => categoty)
    ?.map((rate) => rate.rates)
    ?.flat()?.length;

  categoryRate?.categories?.forEach((category, categoryIndex) => {
    category?.rates?.forEach((rate, rateIndex) => {
      const rowData = {
        category_name: category?.category_info?.code_name,
        category_id: category?.category_info?.id,
        factoring_type: categoryRate?.factoring_type?.type,
        factoring_type_id: categoryRate?.factoring_type?.id,
        rates_length: rateIndex === 0 ? category?.rates?.length : 0,
        category_length: categoryIndex === 0 && rateIndex === 0 ? allRatesLength : 0,
        ...(categoryRate?.timestamp && { timestamp: categoryRate?.timestamp }),
        ...(rate?.id && { id: rate?.id }),
        ...rate,
      };

      categoryRatesData.push(rowData);
    });
  });

  return categoryRatesData;
};

export const formatHistoryCategoryRates = (historyRates) => {
  const historyRatesData: Properties[] = [];

  historyRates?.forEach((historyRate) => {
    const { timestamp, data } = historyRate || {};

    Object.entries(data)?.forEach(([regressionType, categories]) => {
      (categories as HistoryRatesData[])?.forEach((category) => {
        category?.rates?.forEach((rate, i) => {
          const rowData = {
            timestamp,
            regressionType,
            category_info: category?.category_info,
            rates_length: i === 0 ? category?.rates?.length : 0,
            ...rate,
          };

          historyRatesData.push(rowData);
        });
      });
    });
  });

  return historyRatesData;
};

export const getFormattedChartName = (item, dataLength: number, t) => {
  const monthLabel = t(`month${item?.month}`)?.slice(0, 3);
  const yearLabel = `${item.year}`?.slice(2, 4);

  return dataLength <= 12 ? monthLabel : `${monthLabel} ${yearLabel}`;
};
