import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Loader, Row, Col, DatePicker, Label } from 'ebs-design';
import { useReports } from 'hooks';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfYearDateFormat,
  formatDateFilters,
} from 'libs';
import { getFormattedChartName } from 'utils';
import { ReportType, ReportInvoicesMonthlyStatuses } from 'types';
import { InvoicesMonthlyChart } from './charts';
import { InvoicesMonthlyTable } from './tables';
import { InvoicesMonthlyChartItem } from './interfaces';
import { RangePickerStateProps } from './utils';

export const InvoicesMonthlyReport: React.FC = () => {
  const { t } = useIntl();
  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  const { data, isLoading } = useReports<
    ReportInvoicesMonthlyStatuses[],
    InvoicesMonthlyChartItem[]
  >(
    ReportType.INVOICES_MONTHLY_STATUSES,
    {
      ...(range?.[0] && { date_from: range?.[0] && formatDateFilters(range?.[0]) }),
      ...(range?.[1] && { date_to: range?.[1] && formatDateFilters(range?.[1]) }),
    },
    {
      keepPreviousData: true,
      select: (data = []) =>
        data.map((item) => ({
          month: item.month,
          name: getFormattedChartName(item, data?.length, t),
          amount: item.amount_total,
          total: item.total,
        })),
    },
  );

  React.useEffect(() => {
    setRange([firstDayOfYearDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <>
      <Space wrap className="mb-15" align="start" direction="vertical">
        <h4>{t('average_sales_report')}</h4>

        <Space>
          <Label text="Period" />
          <DatePicker.RangeInput
            value={range}
            onChange={(value) => setRange(value as any)}
            isClearable
            placeholderText={t('choose_period')}
            dateFormat={dateFormat}
          />
        </Space>
      </Space>
      <Loader loading={isLoading}>
        <Row>
          <Col size={5}>
            <InvoicesMonthlyTable data={data} />
          </Col>
          <Col size={7}>
            <InvoicesMonthlyChart data={data} />
          </Col>
        </Row>
      </Loader>
    </>
  );
};
